.letsgo {
  &--logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.bus-route-widget {
  .ant-card-body {
    padding: 12px !important;
  }
  &--bus {
    margin: 5px 0;
    padding: 4px;
    background: #d9f7be;
    border-radius: 10px;
    &.outdated {
      opacity: 0.5;
        background: #d9d9d9;
    }
  }
  &--bus-title {
    font-weight: bold;
  }
}

