.app {
  padding: 20px 20px 64px;
  position: relative;
  min-height: calc(100vh - 64px);
  .footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px - 40px);
    text-align: center;
    padding: 10px;
  }
}
.fab-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  line-height: 1.5;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 24px;
  line-height: 1.5;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.detail-card {
  //margin: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
}

.logo--main {
  width: 75px;
}
.logo--letsgo {
  width: 130px;
}
.std-margin-bottom {
  margin-bottom: 16px
}

$yellow: #FF7700;
$red: #CC1413;
